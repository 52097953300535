<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>{{ $t('Set password') }}</h1>

        <div v-if="loading">
          <b-spinner label="Spinning"></b-spinner>
        </div>

        <div v-if="loading == false && verified == false">
          {{ $t('Link invalid') }}
        </div>

        <div class="alert alert-danger" role="alert" v-if="errorMessage != ''">
          {{ $t(errorMessage) }}.
        </div>

        <div v-if="verified && !loading && !success">
          <p class="mt-4">{{ $t('Please enter your new password') }}:</p>

          <form>
            <table>
              <tr>
                <td><label for="password">{{ $t('Password') }}</label></td>
                <td><b-form-input id="password" type="password" v-model="password" required autofocus /></td>
              </tr>
              <tr>
                <td><label for="password">{{ $t('Repeat password') }}</label></td>
                <td><b-form-input id="password_check" type="password" v-model="passwordCheck" required /></td>
              </tr>
              <tr>
                <td></td>
                <td><b-button :disabled="!valid()" variant="primary" type="submit" @click.prevent="handleSubmit">{{ $t('Save') }}</b-button></td>
              </tr>
            </table>
          </form>
        </div>

        <div v-if="success">
          {{ $t('Your password has been saved') }}. 
          <router-link :to="{ name: 'login' }">
            {{ $t('You can now login') }}
          </router-link>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'PasswordReset',
    components: {
    },
    data () {
      return {
        resetUrl: '',
        password: '',
        passwordCheck: '',
        errorMessage: '',
        loading: true,
        verified: false,
        success: false,
      }
    },
    props: {
      resetKey: String,
      username: String,
    },
    methods: {
      valid () {
        return (this.password.length > 7 && this.password == this.passwordCheck)
      },
      handleSubmit () {
        if (this.password.length > 0) {
          this.loading = true

          this.$http.put(this.resetUrl, {
            password: this.password
          })
          .then(() => {
            this.loading = false
            this.success = true
          })
          .catch(() => {
            this.loading = false
            this.errorMessage = 'An unknown error occurred, please reload the page'
          });
        }
      }
    },
    mounted () {
      if (localStorage.getItem('jwt') != null) {
        // Logout first
        localStorage.removeItem('jwt')
        history.go()
      }

      // Check key
      this.resetUrl = 'password-reset/'+ this.username +'/' + this.resetKey
      let provider = this.$route.query.provider
      if (provider) this.resetUrl = this.resetUrl + '/' + provider
      
      this.$http.get(this.resetUrl)
        .then(response => {
          this.user = response.data
          this.verified = true
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.verified = false
        }); 
    }
  };
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  td:first-child {
    padding-right: 40px; 
  }


</style>